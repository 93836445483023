import logo from "./logo.svg";
import "./App.css";
import * as React from "react";
import PropTypes from "prop-types";
// import stylex from "stylex";
import AppBar from "@mui/material/AppBar";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Collapse from "@mui/material/Collapse";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Input from "@mui/material/Input";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import { visuallyHidden } from "@mui/utils";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import DHSLogo from "./allegheny-logo.png";
import PolydeltaWebLogo from "./polydelta-web-logo.svg";
import { Divider, Tab } from "@mui/material";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://polydelta.ai/" target="_blank">
        Polydelta AI
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme();

const tableHeaders = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Provider",
  },
  {
    id: "as",
    numeric: true,
    disablePadding: false,
    label: "Adult Services",
  },
  {
    id: "cs",
    numeric: true,
    disablePadding: false,
    label: "Children's Services",
  },
];

const insuranceProviders = [
  "Blue Cross & Blue Shield PA",
  "Cigna",
  "Highmark",
  "Medicare",
  "Medicaid",
];

const careTypes = {
  as: "Adult Services",
  cs: "Children's Services",
};

function App() {
  // sort capacity table in ascending or descending capacity order
  const [order, setOrder] = useState("asc");
  // care type to order capacity table by
  const [orderBy, setOrderBy] = useState("as");
  // selected row in capacity table's name
  const [selected, setSelected] = useState("");
  const [page, setPage] = useState("capacity");
  const [timeframe, setTimeframe] = useState("week");
  const [providerInput, setProviderInput] = useState("");

  const selectProviderText =
    "See all outpatient care provider availability in the next:";
  const patientInfoText =
    "Search for Medicare/Medicaid outpatient service availability";
  const capacityInputDesc =
    "For each care type Mercy Behavioral Health provides, input it's maximum capacity and the number of " +
    "appointments booked each week for the next 4 weeks after this week.";

  const [providers, setProviders] = useState([
    {
      name: "Mercy Behavioral Health",
      label: "Mercy Behavioral Health",
      id: 1,
      link: "https://www.pittsburghmercy.org/behavioral-health/pittsburgh-mercy-behavioral-health/",
      as_week: 17,
      as_month: 62,
      cs_week: 8,
      cs_month: 34,
      acceptedProviders: [
        "Blue Cross & Blue Shield PA",
        "Cigna",
        "Highmark",
        "Medicare",
        "Medicaid",
      ],
      locations: [
        {
          name: "Mercy Behavioral Location 1",
          id: 1.1,
          location: "cheese",
          as_week: 2,
          as_month: 4,
          cs_week: 1,
          cs_month: 6,
          distance: 6.2,
        },
        {
          name: "Mercy Behavioral Location 2",
          id: 1.2,
          location: "cheese",
          as_week: 4,
          as_month: 18,
          cs_week: 2,
          cs_month: 10,
          distance: 9.9,
        },
        {
          name: "Mercy Behavioral Location 3",
          id: 1.3,
          location: "cheese",
          as_week: 9,
          as_month: 29,
          cs_week: 4,
          cs_month: 11,
          distance: 19.5,
        },
        {
          name: "Mercy Behavioral Location 4",
          id: 1.4,
          location: "cheese",
          as_week: 2,
          as_month: 11,
          cs_week: 1,
          cs_month: 7,
          distance: 2.2,
        },
      ],
    },
    {
      name: "Southwestern Human Services",
      label: "Southwestern Human Services",
      id: 2,
      link: "https://shs-pgh.org/outpatient/",
      as_week: 5,
      as_month: 19,
      cs_week: 8,
      cs_month: 24,
      acceptedProviders: ["Cigna", "Highmark", "Medicare", "Medicaid"],
      locations: [
        {
          name: "Southwestern Human Services Location 1",
          id: 2.1,
          location: "cheese",
          as_week: 0,
          as_month: 0,
          cs_week: 4,
          cs_month: 17,
          distance: 8.6,
        },
        {
          name: "Southwestern Human Services Location 2",
          id: 2.2,
          location: "cheese",
          as_week: 3,
          as_month: 11,
          cs_week: 2,
          cs_month: 4,
          distance: 17.5,
        },
        {
          name: "Southwestern Human Services Location 3",
          id: 2.3,
          location: "cheese",
          as_week: 2,
          as_month: 8,
          cs_week: 2,
          cs_month: 3,
          distance: 9.2,
        },
      ],
    },
    {
      name: "UPMC Western Psychiatric Hospital",
      label: "UPMC Western Psychiatric Hospital",
      id: 3,
      link: "https://www.upmc.com/locations/hospitals/western-psychiatric",
      as_week: 11,
      as_month: 27,
      cs_week: 0,
      cs_month: 4,
      acceptedProviders: ["Blue Cross & Blue Shield PA", "Cigna", "Medicaid"],
      locations: [
        {
          name: "UPMC Western Psychiatric Hospital Location 1",
          id: 3.1,
          location: "cheese",
          as_week: 7,
          as_month: 19,
          cs_week: 0,
          cs_month: 3,
          distance: 10.9,
        },
        {
          name: "UPMC Western Psychiatric Hospital Location 2",
          id: 3.2,
          location: "cheese",
          as_week: 4,
          as_month: 8,
          cs_week: 0,
          cs_month: 1,
          distance: 7.0,
        },
      ],
    },
    {
      name: "University of Pittsburgh Physicians",
      label: "University of Pittsburgh Physicians",
      id: 4,
      link: "https://www.upmc.com/",
      as_week: 7,
      as_month: 12,
      cs_week: 2,
      cs_month: 5,
      acceptedProviders: [
        "Blue Cross & Blue Shield PA",
        "Medicare",
        "Medicaid",
      ],
      locations: [
        {
          name: "University of Pittsburgh Physicians Location 1",
          id: 4.1,
          location: "cheese",
          as_week: 2,
          as_month: 4,
          cs_week: 2,
          cs_month: 5,
          distance: 15.4,
        },
        {
          name: "University of Pittsburgh Physicians Location 2",
          id: 4.2,
          location: "cheese",
          as_week: 5,
          as_month: 8,
          cs_week: 0,
          cs_month: 0,
          distance: 4.3,
        },
      ],
    },
    {
      name: "Wesley Family Services",
      label: "Wesley Family Services",
      id: 5,
      link: "https://wfspa.org/",
      as_week: 0,
      as_month: 5,
      cs_week: 7,
      cs_month: 23,
      acceptedProviders: [
        "Blue Cross & Blue Shield PA",
        "Cigna",
        "Highmark",
        "Medicare",
      ],
      locations: [
        {
          name: "Wesley Family Services Location 1",
          id: 5.1,
          location: "cheese",
          as_week: 0,
          as_month: 3,
          cs_week: 3,
          cs_month: 8,
          distance: 7.4,
        },
        {
          name: "Wesley Family Services Location 2",
          id: 5.2,
          location: "cheese",
          as_week: 0,
          as_month: 2,
          cs_week: 4,
          cs_month: 15,
          distance: 6.8,
        },
      ],
    },
  ]);

  function descendingComparator(a, b, orderBy) {
    const key = orderBy + "_" + timeframe;
    if (b[key] < a[key]) {
      return -1;
    }
    if (b[key] > a[key]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function EnhancedTableHead(props) {
    const { order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {tableHeaders.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography style={styles.headers}>{headCell.label}</Typography>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage != null) setPage(newPage);
  };

  const handleChangeTimeframe = (event, newTimeframe) => {
    if (newTimeframe != null) setTimeframe(newTimeframe);
  };

  function ProviderDetails(props) {
    const { open } = props;
    return <div />;
  }

  function CapacityPage() {
    function CapacityTableRow(props) {
      const { row, index } = props;

      const [showLocationDetails, setShowLocationDetails] = useState(false);
      // selected provider location name in capacity table
      const [selectedLocation, setSelectedLocation] = useState("");
      const [showProviderContactDialog, setShowProviderContactDialog] =
        useState(false);

      const handleClickCapacityTableRow = (event, name) => {
        setSelected(name);
        setShowLocationDetails(!showLocationDetails);
      };

      const handleClickLocationTableRow = (event, name) => {
        setSelectedLocation(name);
        setShowProviderContactDialog(!showProviderContactDialog);
      };

      const handleShowProviderContactDialogClose = () => {
        setShowProviderContactDialog(false);
      };

      function LocationContactDialog() {
        const [submittedEmail, setSubmittedEmail] = useState(false);
        const enterEmailMessage =
          "Enter your email address below to receive the contact information for " +
          selectedLocation +
          "'s scheduling department:";
        const submittedEmailMessage =
          selectedLocation +
          "'s scheduling departmnent's contact info has been emailed to you!";

        return (
          <Dialog
            open={showProviderContactDialog}
            onClose={handleShowProviderContactDialogClose}
            TransitionComponent={Transition}
          >
            <DialogTitle>Get Provider Location Contact Info</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {!submittedEmail ? enterEmailMessage : submittedEmailMessage}
              </DialogContentText>
              {!submittedEmail ? <hr /> : <div />}
              {!submittedEmail && (
                <Box style={styles.locationTableEmailSubmitContainer}>
                  <TextField
                    label="Email Address"
                    id="emailAddressLocationTable"
                    defaultValue=""
                    variant="filled"
                    style={styles.emailAddressFieldLocationTable}
                  />
                  <Button
                    variant="contained"
                    onClick={() => {
                      setSubmittedEmail(true);
                    }}
                  >
                    Get Contact Info
                  </Button>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleShowProviderContactDialogClose}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        );
      }

      return (
        <React.Fragment>
          <TableRow
          // DEPRECRATED: allowing capacity rows to be selectable makes the location dropdown possible by clicking a row but also
          // requires a double-click at first try and can't have multiple location tables open at once. The second issue really stems
          // from the selected state not being visible to the location table, which goes out of scope (and therefore unmounts)
          // when we move up component levels to find selected state.
          // hover
          // onClick={(event) => handleClickCapacityTableRow(event, row.name)}
          // tabIndex={-1}
          // key={row.name}
          // selected={row.name === selected}
          >
            <TableCell
              style={styles.providerNameDropdownCell}
              component="th"
              id={row.name}
              scope="row"
              padding="none"
            >
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setShowLocationDetails(!showLocationDetails)}
              >
                {showLocationDetails ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
              <Typography>{row.name}</Typography>
            </TableCell>
            <TableCell align="right">
              {timeframe === "week" ? row.as_week : row.as_month}
            </TableCell>
            <TableCell align="right">
              {timeframe === "week" ? row.cs_week : row.cs_month}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={showLocationDetails} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    Locations
                  </Typography>
                  <Table size="small" aria-label="location capacity">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography style={styles.headers}>
                            Location
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography style={styles.headers}>
                            Adult Services
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography style={styles.headers}>
                            Children's Services
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.locations.map((locationRow) => (
                        <TableRow
                          key={locationRow.name}
                          hover
                          onClick={(event) =>
                            handleClickLocationTableRow(event, locationRow.name)
                          }
                          tabIndex={-1}
                          selected={locationRow.name === selectedLocation}
                        >
                          <TableCell component="th" scope="row">
                            {locationRow.name}
                          </TableCell>
                          <TableCell align="right">
                            {timeframe === "week"
                              ? locationRow.as_week
                              : locationRow.as_month}
                          </TableCell>
                          <TableCell align="right">
                            {timeframe === "week"
                              ? locationRow.cs_week
                              : locationRow.cs_month}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
          <LocationContactDialog />
        </React.Fragment>
      );
    }

    return (
      <TableContainer style={styles.tableContainer}>
        <Table style={styles.table} aria-labelledby="tableTitle" size="large">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={providers.length}
          />
          <TableBody>
            {providers
              .slice()
              .sort(getComparator(order, orderBy))
              .map((row, index) => {
                return <CapacityTableRow row={row} index={index} />;
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  function RecommendationPage() {
    const [insurance, setInsurance] = useState("");
    const [birthDate, setBirthDate] = useState(null);
    const [patientName, setPatientName] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [zipCode, setZipCode] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [emailAddress, setEmailAddress] = useState("");

    const handleDialogClose = () => {
      setDialogOpen(false);
    };

    // dont display options if there is an error, set this to true after all error checking/ ensure > 0 options
    let error = true;

    // return true if patient >= 18 yo, false otherwise
    const isAdult = () => {
      //get adult cutoff by subtracting 18 years from todays date
      const adult = new Date();
      adult.setFullYear(adult.getFullYear() - 18);
      adult.getTime();

      const birth = new Date(birthDate).getTime();

      return birth <= adult;
    };

    function RecommendationDialog() {
      // array containing names of provider locations whose contact info has already been requested
      const [requestedLocations, setRequestedLocations] = useState([]);
      const [selectedLocation, setSelectedLocation] = useState("");

      let message =
        "Make sure the First Name, Date of Birth, Insurance Provider, Email Address, and all address fields are filled.";

      const careType = isAdult() ? "as" : "cs";
      // key to access provider capacity data, show providers with availability in the next month
      let key = careType + "_" + "month";

      const providerContactButtonText = "Share Provider Info";
      const providerInfoSentText =
        "'s scheduling department contact info has been emailed to you!";

      const recommendationTableDescription =
        "Below are more providers with capacity for " +
        careTypes[careType] +
        " in the next 2 weeks and 4 weeks.";

      const clickProviderText =
        (requestedLocations.length === 0
          ? "Click  a provider location then click the '" +
            providerContactButtonText +
            "' button to have their contact info emailed to you. "
          : "") +
        "Selected Location: " +
        selectedLocation;

      // provider recommendation options, highest capacity/closest distance at the top
      let options = [];

      let recommendedProviderLink = null;
      let recommendedProviderName = null;
      let recommendedProviderCapacity = 0;
      let shortestDist = 100;

      const getOptions = () => {
        let retArray = [];
        providers.forEach((provider) => {
          if (
            provider.acceptedProviders.indexOf(insurance) != -1 &&
            provider[key] > 0
          ) {
            provider.locations.forEach((location) => {
              if (location.distance < shortestDist) {
                shortestDist = location.distance;
                recommendedProviderLink = provider.link;
                recommendedProviderName = provider.name;
                recommendedProviderCapacity = location[key];
              }
              if (location[key] > 0) {
                retArray.push(location);
              }
            });
          }
        });

        // sort location options in distance order
        retArray.sort((a, b) => a.distance - b.distance);
        retArray = retArray.slice(0, 5);

        return retArray;
      };

      if (
        patientName !== "" &&
        careType !== "" &&
        insurance !== "" &&
        birthDate != null &&
        address !== "" &&
        city !== "" &&
        state !== "" &&
        zipCode != "" &&
        emailAddress != ""
      ) {
        if (insurance !== "Medicaid" && insurance !== "Medicare") {
          message =
            "Error: This tool is for use by Medicare and Medicaid patients only.";
        } else {
          options = getOptions();

          if (options.length > 0) {
            message =
              "Try scheduling " +
              patientName +
              " with " +
              recommendedProviderName +
              ". They accept " +
              insurance +
              " insurance and have a location near you with " +
              recommendedProviderCapacity +
              " open slots for " +
              careTypes[careType] +
              " in the next 4 weeks.";

            error = false;
          } else {
            message =
              "Hmm... sorry, but it looks like 0 providers that accept " +
              patientName +
              "'s insurance have open slots for " +
              careTypes[careType] +
              " right now. Try again later!";
          }
        }
      }

      const handleClickRecommendationTableRow = (event, name) => {
        setSelectedLocation(name);
      };

      return (
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          TransitionComponent={Transition}
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle>{error ? "Error" : "Recommended Provider"}</DialogTitle>
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
            {!error && (
              <Box>
                <hr />
                <DialogContentText>
                  {recommendationTableDescription}
                </DialogContentText>
                <TableContainer style={styles.recommendationTableContainer}>
                  <Table style={styles.recommendationTable}>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography style={styles.headers}>
                            Provider
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography style={styles.headers}>
                            2-Weeks
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography style={styles.headers}>
                            4-Weeks
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography style={styles.headers}>
                            Distance
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {options.map((provider) => (
                        <TableRow
                          key={provider.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          hover
                          onClick={(event) =>
                            handleClickRecommendationTableRow(
                              event,
                              provider.name
                            )
                          }
                          tabIndex={-1}
                          selected={provider.name === selectedLocation}
                        >
                          <TableCell component="th" scope="row">
                            {provider.name}
                          </TableCell>
                          <TableCell align="right">
                            {provider[careType + "_week"]}
                          </TableCell>
                          <TableCell align="right">
                            {provider[careType + "_month"]}
                          </TableCell>
                          <TableCell align="right">
                            {provider.distance + " mi"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
            {!error && (
              <Box>
                <hr />
                <DialogContentText>
                  {!requestedLocations.includes(selectedLocation)
                    ? clickProviderText
                    : selectedLocation + providerInfoSentText}
                </DialogContentText>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            {!error && (
              <Box>
                {!requestedLocations.includes(selectedLocation) && (
                  <Button
                    onClick={() => {
                      const copyLocs = [...requestedLocations];
                      copyLocs.push(selectedLocation);
                      setRequestedLocations(copyLocs);
                    }}
                  >
                    {providerContactButtonText}
                  </Button>
                )}
                <Button
                  onClick={() => {
                    if (options.length > 0)
                      window.open(recommendedProviderLink, "_blank");
                  }}
                >
                  Provider Site
                </Button>
              </Box>
            )}
            <Button onClick={handleDialogClose}>Close</Button>
          </DialogActions>
        </Dialog>
      );
    }

    const handleChangeZipCode = (event) => {
      let val = event.target.value;

      if (val.length > 5) {
        val = val.substr(0, val.length - 1);
      }

      event.target.value = val;
      setZipCode(event.target.value);
    };

    return (
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 1,
        }}
        style={styles.recForm}
      >
        <Box>
          <Box>
            <Typography style={styles.sectionHeader}>
              Patient Personal Information
            </Typography>
          </Box>
          <TextField
            label="First Name"
            id="firstName"
            variant="filled"
            value={patientName}
            required={true}
            onChange={(event) => {
              setPatientName(event.target.value);
            }}
          />
          <TextField
            label="Last Name"
            id="lastName"
            defaultValue=""
            variant="filled"
            style={styles.lastName}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date of Birth"
              id="dob"
              style={styles.dateField}
              disableFuture={true}
              required={true}
              onChange={(newDate) => {
                setBirthDate(newDate);
              }}
            />
          </LocalizationProvider>
          <FormControl>
            <InputLabel sx={{ marginLeft: 1 }}>Insurance Provider</InputLabel>
            <Select
              value={insurance}
              label="Insurance Provider"
              required={true}
              onChange={(event) => {
                setInsurance(event.target.value);
              }}
              style={styles.insuranceField}
            >
              <MenuItem value={"Medicare"}>Medicare</MenuItem>
              <MenuItem value={"Medicaid"}>Medicaid</MenuItem>
              <MenuItem value={"Neither"}>Neither</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Box>
            <Typography style={styles.sectionHeader}>
              Patient Address Information
            </Typography>
          </Box>
          <Box style={styles.addressFields}>
            <TextField
              label="Address"
              id="address"
              value={address}
              onChange={(event) => {
                setAddress(event.target.value);
              }}
              variant="filled"
              style={styles.addressField}
            />
            <TextField
              label="City"
              id="city"
              value={city}
              onChange={(event) => {
                setCity(event.target.value);
              }}
              variant="filled"
              style={styles.cityField}
            />
            <FormControl>
              <InputLabel>State</InputLabel>
              <Select
                value={state}
                label="State"
                required={true}
                onChange={(event) => {
                  setState(event.target.value);
                }}
                style={styles.stateField}
              >
                <MenuItem value={"PA"}>PA</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="5-Digit ZIP Code"
              id="zipCode"
              variant="filled"
              type="number"
              InputProps={{
                inputProps: { min: 10000, max: 99999 },
              }}
              value={zipCode}
              onChange={(event) => {
                handleChangeZipCode(event);
              }}
              style={styles.zipCodeField}
            />
            <TextField
              label="Email Address"
              id="emailAddressRecommendationPage"
              variant="filled"
              value={emailAddress}
              onChange={(event) => {
                setEmailAddress(event.target.value);
              }}
              style={styles.emailAddressFieldRecommendationPage}
            />
          </Box>
        </Box>
        <Box>
          <Button
            variant="contained"
            onClick={() => {
              setDialogOpen(true);
            }}
            style={styles.findProvider}
          >
            Find Provider
          </Button>
        </Box>
        <RecommendationDialog />
      </Box>
    );
  }

  function CapacityInputPage() {
    const getLastWeekDate = () => {
      const ret = new Date();
      ret.setDate(ret.getDate() - 7);
      return ret;
    };

    const [submitCapacityDialogOpen, setSubmitCapacityDialogOpen] =
      useState(false);
    const [uploadCapacityDialogOpen, setUploadCapacityDialogOpen] =
      useState(false);
    const [capacityUploaded, setCapacityUploaded] = useState(false);
    const [lastSubmittedDate, setLastSubmittedDate] = useState(
      getLastWeekDate()
    );

    const submitCapacityText =
      "Mercy Behavioral Health's capacity numbers for the next 4 weeks have been submitted to the " +
      " Allegheny County Outpatient Provider Capacity Management System.";
    const uploadCapacityText =
      "Upload a file containing Mercy Behavioral Health's capacity data for the next 4 weeks below: ";

    // lastSubmittedDate.setDate(lastSubmittedDate.getDate() - 7);

    const lastSubmittedText =
      "Last submitted on " +
      (lastSubmittedDate.getMonth() + 1) +
      "-" +
      lastSubmittedDate.getDate() +
      "-" +
      lastSubmittedDate.getFullYear();

    const handleChangeCapacityInput = (event) => {
      let val = event.target.value;

      if (val.length > 3) {
        val = val.substr(0, val.length - 1);
      }

      event.target.value = val;
    };

    const getSubmitCapacityDialog = () => {
      const handleSubmitCapacityDialogClose = () => {
        setLastSubmittedDate(new Date());
        setSubmitCapacityDialogOpen(false);
      };

      return (
        <Dialog
          open={submitCapacityDialogOpen}
          onClose={handleSubmitCapacityDialogClose}
          TransitionComponent={Transition}
        >
          <DialogTitle>{"Capacity Submitted!"}</DialogTitle>
          <DialogContent>
            <DialogContentText>{submitCapacityText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmitCapacityDialogClose}>Close</Button>
          </DialogActions>
        </Dialog>
      );
    };

    const getUploadCapacityDialog = () => {
      const handleUploadCapacityDialogClose = () => {
        setUploadCapacityDialogOpen(false);
      };

      return (
        <Dialog
          open={uploadCapacityDialogOpen}
          onClose={handleUploadCapacityDialogClose}
          TransitionComponent={Transition}
        >
          <DialogTitle>{"Upload Capacity"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {!capacityUploaded ? uploadCapacityText : submitCapacityText}
            </DialogContentText>
            {!capacityUploaded && (
              <Box>
                <hr />
                <Box style={styles.uploadCapacityFileContainer}>
                  <Input type="file" />
                </Box>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            {!capacityUploaded && (
              <Button
                onClick={() => {
                  setLastSubmittedDate(new Date());
                  setCapacityUploaded(true);
                }}
              >
                Submit Capacity
              </Button>
            )}
            <Button onClick={handleUploadCapacityDialogClose}>Close</Button>
          </DialogActions>
        </Dialog>
      );
    };

    return (
      <Box>
        <TableContainer style={styles.capacityInputTableContainer}>
          <Table style={styles.capacityInputTable}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography style={styles.headers}>Care Type</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography style={styles.headers}>Max Capacity</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography style={styles.headers}>Week 1</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography style={styles.headers}>Week 2</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography style={styles.headers}>Week 3</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography style={styles.headers}>Week 4</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(careTypes).map((careType) => (
                <TableRow
                  key={careType}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography style={styles.capacityInputProviderText}>
                      {careTypes[careType]}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" style={styles.capacityInputCell}>
                    <TextField
                      label="#"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, max: 999 },
                      }}
                      id={careType + "_max"}
                      defaultValue=""
                      variant="filled"
                      style={styles.capacityInputField}
                      onChange={handleChangeCapacityInput}
                    />
                  </TableCell>
                  <TableCell align="right" style={styles.capacityInputCell}>
                    <TextField
                      label="#"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, max: 999 },
                      }}
                      id={careType + "_week1"}
                      defaultValue=""
                      variant="filled"
                      style={styles.capacityInputField}
                      onChange={handleChangeCapacityInput}
                    />
                  </TableCell>
                  <TableCell align="right" style={styles.capacityInputCell}>
                    <TextField
                      label="#"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, max: 999 },
                      }}
                      id={careType + "_week2"}
                      defaultValue=""
                      variant="filled"
                      style={styles.capacityInputField}
                      onChange={handleChangeCapacityInput}
                    />
                  </TableCell>
                  <TableCell align="right" style={styles.capacityInputCell}>
                    <TextField
                      label="#"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, max: 999 },
                      }}
                      id={careType + "_week3"}
                      defaultValue=""
                      variant="filled"
                      style={styles.capacityInputField}
                      onChange={handleChangeCapacityInput}
                    />
                  </TableCell>
                  <TableCell align="right" style={styles.capacityInputCell}>
                    <TextField
                      label="#"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, max: 999 },
                      }}
                      id={careType + "_week4"}
                      defaultValue=""
                      variant="filled"
                      style={styles.capacityInputField}
                      onChange={handleChangeCapacityInput}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box style={styles.capacityInputSubmitButtonBox}>
          <Button
            variant="contained"
            onClick={() => {
              setSubmitCapacityDialogOpen(true);
            }}
            style={styles.submitCapacityButton}
          >
            Submit Capacity
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setUploadCapacityDialogOpen(true);
            }}
            style={styles.uploadCapacityButton}
          >
            Upload Capacity Data
          </Button>
        </Box>
        {getSubmitCapacityDialog()}
        {getUploadCapacityDialog()}
        <Box style={styles.lastSubmittedContainer}>
          <Typography>{lastSubmittedText}</Typography>
        </Box>
      </Box>
    );
  }

  const getPage = () => {
    if (page === "capacity") {
      return <CapacityPage />;
    } else if (page === "recommend") {
      return <RecommendationPage />;
    } else if (page === "input") {
      return <CapacityInputPage />;
    } else {
      return null;
    }
  };

  const getPageTitle = () => {
    let title = "Outpatient Provider ";
    if (page === "capacity") {
      title += "Capacity";
    } else if (page === "recommend") {
      title += "Recommendation";
    } else if (page === "input") {
      title = "Mercy Behavioral Health Capacity";
    } else {
      return null;
    }

    return title;
  };

  const getPageDescription = () => {
    if (page === "capacity") {
      return selectProviderText;
    } else if (page === "recommend") {
      return patientInfoText;
    } else if (page === "input") {
      return capacityInputDesc;
    } else {
      return null;
    }
  };

  const getAppBarTitle = () => {
    let title = "Outpatient Providers";
    if (page === "input") {
      title = "Mercy Behavioral Health";
    }

    return title;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative" style={styles.appBar}>
        <Toolbar style={styles.toolbar}>
          <Box style={styles.headerLogo}>
            <img src={DHSLogo} />
          </Box>
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            sx={{ mx: "10px", color: "gray", fontSize: "25px" }}
          >
            |
          </Typography>
          <Typography variant="h6" color="inherit" noWrap>
            {getAppBarTitle()}
          </Typography>
          <Box style={styles.pageToggle}>
            <ToggleButtonGroup
              color="primary"
              value={page}
              exclusive
              onChange={handleChangePage}
              aria-label="Page"
              style={styles.toggleButtonGroup}
            >
              <ToggleButton value="capacity">Capacity</ToggleButton>
              <ToggleButton value="recommend">Recommend</ToggleButton>
              <ToggleButton value="input">Input</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Toolbar>
      </AppBar>
      <main>
        <Box style={styles.page}>
          <Box
            sx={{
              bgcolor: "background.paper",
              pt: 8,
            }}
          >
            <Container style={styles.title}>
              <Typography
                component="h1"
                variant="h3"
                align="center"
                color="text.primary"
                gutterBottom
              >
                {getPageTitle()}
              </Typography>
              <Box style={styles.timeframeBox}>
                <Box style={styles.timeframeToggleText}>
                  <Typography
                    variant="h5"
                    align="center"
                    color="text.secondary"
                    paragraph
                  >
                    {getPageDescription()}
                  </Typography>
                </Box>
                {page === "capacity" && (
                  <ToggleButtonGroup
                    color="primary"
                    value={timeframe}
                    exclusive
                    onChange={handleChangeTimeframe}
                    aria-label="Capacity Timeframe"
                    style={styles.timeframeToggle}
                  >
                    <ToggleButton value="week">2-Weeks</ToggleButton>
                    <ToggleButton value="month">4-Weeks</ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Box>
              {page === "capacity" ? (
                // DEPRECATED: data from this box would let you also type to select a provider on the capacity page for more info, more info not implemented
                // <Autocomplete
                //   disablePortal
                //   options={providers}
                //   value={selected}
                //   onChange={(event, newValue) =>
                //     handleClickProvider(event, newValue?.name)
                //   }
                //   inputValue={providerInput}
                //   onInputChange={(event, newInputValue) => {
                //     setProviderInput(newInputValue);
                //   }}
                //   style={styles.searchBar}
                //   renderInput={(params) => (
                //     <TextField {...params} label="Provider" />
                //   )}
                // />
                <div />
              ) : (
                <Divider />
              )}
            </Container>
            {getPage()}
          </Box>
          <Box
            component="footer"
            sx={{
              py: 3,
              px: 2,
              mt: "auto",
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[200]
                  : theme.palette.grey[800],
            }}
            style={styles.footerBox}
          >
            <Container maxWidth="sm">
              <Copyright />
            </Container>
          </Box>
        </Box>
      </main>
    </ThemeProvider>
  );
}

const styles = {
  headerLogo: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "black",
    alignSelf: "center",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "875px",
  },
  searchBar: {
    width: 325,
    marginBottom: "10px",
  },
  tableRow: {
    cursor: "pointer",
  },
  toolbar: {
    display: "flex",
    width: "100%",
  },
  pageToggle: {
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1,
  },
  toggleButtonGroup: {
    backgroundColor: "white",
  },
  timeframeToggle: {
    maxHeight: "40px",
  },
  timeframeBox: {
    display: "flex",
    alignItems: "center",
  },
  timeframeToggleText: {
    alignItems: "center",
    paddingTop: "10px",
    marginRight: "5px",
  },
  recForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  insuranceField: {
    width: 200,
    backgroundColor: theme.palette.grey[200],
    marginLeft: 10,
  },
  careType: {
    width: 300,
    backgroundColor: theme.palette.grey[200],
  },
  dateField: {
    backgroundColor: theme.palette.grey[200],
  },
  addressFields: {
    width: "inherit",
    display: "flex",
  },
  addressField: {
    marginRight: 10,
    width: 300,
  },
  cityField: {
    marginRight: 10,
  },
  zipCodeField: {
    width: 150,
    marginRight: 10,
  },
  stateField: {
    width: 100,
    backgroundColor: theme.palette.grey[200],
    marginRight: 10,
  },
  lastName: {
    marginRight: 10,
    marginLeft: 10,
  },
  findProvider: {
    marginTop: 20,
    marginBottom: 10,
  },
  sectionHeader: {
    fontSize: "20px",
  },
  tableContainer: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 100,
  },
  table: {
    maxWidth: 800,
  },
  headers: {
    // fontSize: '13px',
    fontWeight: "bold",
  },
  footerBox: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
  capacityInputField: {
    width: 70,
  },
  capacityInputCell: {},
  capacityInputTableContainer: {
    display: "flex",
    justifyContent: "center",
  },
  recommendationTableContainer: {
    display: "flex",
    justifyContent: "center",
  },
  recommendationTable: {
    minWidth: 400,
    maxWidth: 700,
  },
  capacityInputTable: {
    maxWidth: 1000,
  },
  capacityInputSubmitButtonBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  providerNameDropdownCell: {
    display: "flex",
    alignItems: "center",
    maxWidth: 350,
    height: 55,
  },
  locationTableEmailSubmitContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
  },
  emailAddressFieldLocationTable: {
    width: 300,
    marginRight: 5,
  },
  emailAddressFieldRecommendationPage: {
    width: 250,
  },
  submitCapacityButton: {
    marginRight: 10,
  },
  uploadCapacityFileContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  lastSubmittedContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  page: {
    // display: 'flex',
    // flexDirection: 'column',
    // flexGrow: 1,
    // height: '100%',
    marginTop: 50,
  },
  appBar: {
    backgroundColor: "black",
    position: "fixed",
    top: 0,
    zIndex: 1,
  },
};

export default App;
